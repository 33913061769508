import { default as loginlF98ulYotMMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/auth/login.vue?macro=true";
import { default as request_45passwordUNdrMeTb60Meta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/auth/request-password.vue?macro=true";
import { default as _91token_937NUIWTXlMcMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/auth/reset-password/[token].vue?macro=true";
import { default as _91token_93yIdoeQLZnSMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/auth/setup-password/[token].vue?macro=true";
import { default as bekabeld6tW03EhKssMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/beheer/bekabeld.vue?macro=true";
import { default as import92GK1bEc7EMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/beheer/switch-ports/import.vue?macro=true";
import { default as indexz7AtLz5QU3Meta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/beheer/switch-ports/index.vue?macro=true";
import { default as calculator2Lz5VRGQa9Meta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/calculator.vue?macro=true";
import { default as index1EAngp43KRMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/index.vue?macro=true";
import { default as indexUa4zYJImZxMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/klanten/[slug]/index.vue?macro=true";
import { default as indexxGScGrsv5OMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/klanten/index.vue?macro=true";
import { default as index3CrVlVJr7FMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/medewerkers/[uuid]/index.vue?macro=true";
import { default as create3xvcjlh9MrMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/medewerkers/create.vue?macro=true";
import { default as indexqOp1Tw8KqKMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/medewerkers/index.vue?macro=true";
import { default as index8bA3TqsYAZMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/orders/[slug]/index.vue?macro=true";
import { default as indexiUXLW3H2tdMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/profile/index.vue?macro=true";
import { default as status7keDM7KHp5Meta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/status.vue?macro=true";
export default [
  {
    name: loginlF98ulYotMMeta?.name ?? "auth-login",
    path: loginlF98ulYotMMeta?.path ?? "/auth/login",
    meta: loginlF98ulYotMMeta || {},
    alias: loginlF98ulYotMMeta?.alias || [],
    redirect: loginlF98ulYotMMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: request_45passwordUNdrMeTb60Meta?.name ?? "auth-request-password",
    path: request_45passwordUNdrMeTb60Meta?.path ?? "/auth/request-password",
    meta: request_45passwordUNdrMeTb60Meta || {},
    alias: request_45passwordUNdrMeTb60Meta?.alias || [],
    redirect: request_45passwordUNdrMeTb60Meta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/auth/request-password.vue").then(m => m.default || m)
  },
  {
    name: _91token_937NUIWTXlMcMeta?.name ?? "auth-reset-password-token",
    path: _91token_937NUIWTXlMcMeta?.path ?? "/auth/reset-password/:token()",
    meta: _91token_937NUIWTXlMcMeta || {},
    alias: _91token_937NUIWTXlMcMeta?.alias || [],
    redirect: _91token_937NUIWTXlMcMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93yIdoeQLZnSMeta?.name ?? "auth-setup-password-token",
    path: _91token_93yIdoeQLZnSMeta?.path ?? "/auth/setup-password/:token()",
    meta: _91token_93yIdoeQLZnSMeta || {},
    alias: _91token_93yIdoeQLZnSMeta?.alias || [],
    redirect: _91token_93yIdoeQLZnSMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/auth/setup-password/[token].vue").then(m => m.default || m)
  },
  {
    name: bekabeld6tW03EhKssMeta?.name ?? "beheer-bekabeld",
    path: bekabeld6tW03EhKssMeta?.path ?? "/beheer/bekabeld",
    meta: bekabeld6tW03EhKssMeta || {},
    alias: bekabeld6tW03EhKssMeta?.alias || [],
    redirect: bekabeld6tW03EhKssMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/beheer/bekabeld.vue").then(m => m.default || m)
  },
  {
    name: import92GK1bEc7EMeta?.name ?? "beheer-switch-ports-import",
    path: import92GK1bEc7EMeta?.path ?? "/beheer/switch-ports/import",
    meta: import92GK1bEc7EMeta || {},
    alias: import92GK1bEc7EMeta?.alias || [],
    redirect: import92GK1bEc7EMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/beheer/switch-ports/import.vue").then(m => m.default || m)
  },
  {
    name: indexz7AtLz5QU3Meta?.name ?? "beheer-switch-ports",
    path: indexz7AtLz5QU3Meta?.path ?? "/beheer/switch-ports",
    meta: indexz7AtLz5QU3Meta || {},
    alias: indexz7AtLz5QU3Meta?.alias || [],
    redirect: indexz7AtLz5QU3Meta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/beheer/switch-ports/index.vue").then(m => m.default || m)
  },
  {
    name: calculator2Lz5VRGQa9Meta?.name ?? "calculator",
    path: calculator2Lz5VRGQa9Meta?.path ?? "/calculator",
    meta: calculator2Lz5VRGQa9Meta || {},
    alias: calculator2Lz5VRGQa9Meta?.alias || [],
    redirect: calculator2Lz5VRGQa9Meta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/calculator.vue").then(m => m.default || m)
  },
  {
    name: index1EAngp43KRMeta?.name ?? "index",
    path: index1EAngp43KRMeta?.path ?? "/",
    meta: index1EAngp43KRMeta || {},
    alias: index1EAngp43KRMeta?.alias || [],
    redirect: index1EAngp43KRMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexUa4zYJImZxMeta?.name ?? "klanten-slug",
    path: indexUa4zYJImZxMeta?.path ?? "/klanten/:slug()",
    meta: indexUa4zYJImZxMeta || {},
    alias: indexUa4zYJImZxMeta?.alias || [],
    redirect: indexUa4zYJImZxMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/klanten/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxGScGrsv5OMeta?.name ?? "klanten",
    path: indexxGScGrsv5OMeta?.path ?? "/klanten",
    meta: indexxGScGrsv5OMeta || {},
    alias: indexxGScGrsv5OMeta?.alias || [],
    redirect: indexxGScGrsv5OMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/klanten/index.vue").then(m => m.default || m)
  },
  {
    name: index3CrVlVJr7FMeta?.name ?? "medewerkers-uuid",
    path: index3CrVlVJr7FMeta?.path ?? "/medewerkers/:uuid()",
    meta: index3CrVlVJr7FMeta || {},
    alias: index3CrVlVJr7FMeta?.alias || [],
    redirect: index3CrVlVJr7FMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/medewerkers/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: create3xvcjlh9MrMeta?.name ?? "medewerkers-create",
    path: create3xvcjlh9MrMeta?.path ?? "/medewerkers/create",
    meta: create3xvcjlh9MrMeta || {},
    alias: create3xvcjlh9MrMeta?.alias || [],
    redirect: create3xvcjlh9MrMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/medewerkers/create.vue").then(m => m.default || m)
  },
  {
    name: indexqOp1Tw8KqKMeta?.name ?? "medewerkers",
    path: indexqOp1Tw8KqKMeta?.path ?? "/medewerkers",
    meta: indexqOp1Tw8KqKMeta || {},
    alias: indexqOp1Tw8KqKMeta?.alias || [],
    redirect: indexqOp1Tw8KqKMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/medewerkers/index.vue").then(m => m.default || m)
  },
  {
    name: index8bA3TqsYAZMeta?.name ?? "orders-slug",
    path: index8bA3TqsYAZMeta?.path ?? "/orders/:slug()",
    meta: index8bA3TqsYAZMeta || {},
    alias: index8bA3TqsYAZMeta?.alias || [],
    redirect: index8bA3TqsYAZMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/orders/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexiUXLW3H2tdMeta?.name ?? "profile",
    path: indexiUXLW3H2tdMeta?.path ?? "/profile",
    meta: indexiUXLW3H2tdMeta || {},
    alias: indexiUXLW3H2tdMeta?.alias || [],
    redirect: indexiUXLW3H2tdMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: status7keDM7KHp5Meta?.name ?? "status",
    path: status7keDM7KHp5Meta?.path ?? "/status",
    meta: status7keDM7KHp5Meta || {},
    alias: status7keDM7KHp5Meta?.alias || [],
    redirect: status7keDM7KHp5Meta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/management/pages/status.vue").then(m => m.default || m)
  }
]